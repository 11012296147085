import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 4),
    width: 150,
  },
  card: {
    height: 150,
  },
  cardAction: {
    height: "100%",
  },
}));
