import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    maxWidth: 225,
    margin: theme.spacing(6, "auto", 0),
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.innoBlue.main,
    color: theme.palette.innoBlue.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.innoBlue.light,
      color: theme.palette.innoBlue.dark,
    },
  },
}));
