import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { handleNavigate } from "../../util";
import { CUSTOMERS_SECTION } from "../../util/constants";
import SectionTitle from "../layout/SectionTitle";
import SectionWrapper from "../layout/SectionWrapper";
import CustomerLogo from "./CustomerLogo";
import { CustomerSectionProps } from "./props";
import { useStyles } from "./styles";

const CustomerSection = ({ customers }: CustomerSectionProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const groupItemCount: number = customers.length > 5 ? 5 : customers.length;
  const items = customers.reduce((acc: Array<any>, _, index) => {
    if (index % groupItemCount === 0) {
      acc.push(
        <Grid container key={index.toString()} justifyContent="center">
          {customers.slice(index, index + groupItemCount).map((i) => {
            return (
              <CustomerLogo
                key={i.nom}
                logo={i.logo!}
                nom={i.nom}
                site_web_url={i.site_web_url}
              />
            );
          })}
        </Grid>,
      );
    }
    return acc;
  }, []);

  return (
    <SectionWrapper sectionId={CUSTOMERS_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Ils nous font confiance" />
        <Carousel
          cycleNavigation
          animation="slide"
          autoPlay={true}
          fullHeightHover={false}
          indicators={false}
        >
          {items}
        </Carousel>
        <Button
          onClick={() => handleNavigate("/clients")}
          size={isMobile ? "medium" : "large"}
          className={classes.button}
          aria-label="Découvrir"
        >
          Découvrir nos clients
        </Button>
      </Container>
    </SectionWrapper>
  );
};

export default CustomerSection;
