import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import React from "react";
import Grid from "@mui/material/Grid";
import { useStyles } from "./styles";

const CustomerLogo = ({
  logo,
  site_web_url,
  nom,
}: {
  logo: string;
  site_web_url: string | null;
  nom: string;
}) => {
  const classes = useStyles();

  const imgSrc = "/images/clients/" + logo;

  const handleClick = () => {
    if (site_web_url) {
      window.open(site_web_url, "blank");
    }
  };

  return (
    <Grid item className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea
          disabled={!site_web_url}
          onClick={handleClick}
          className={classes.cardAction}
        >
          <CardMedia
            component="img"
            // sx={{ height: 150 }}
            image={imgSrc}
            alt={nom}
            style={{
              objectFit: "contain",
            }}
          />
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default CustomerLogo;
